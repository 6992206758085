<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" style="z-index: 2;">
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                                <v-col class="px-10">
                                    <v-btn class="mr-4 white--text" color="#37906C" @click="toBack()" v-if="$route.params.paraId !== 'root'">
                                        <v-icon >mdi-arrow-left</v-icon>回上一層
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#37906C" @click="toCreate()">
                                        <v-icon >mdi-note-plus</v-icon>新增
                                    </v-btn>
                                </v-col>
                            </v-row>
                
                            <v-data-table
                            :headers="headers"
                            :items="datalist"
                            :items-per-page="listPageSize"
                            height="800px"
                            hide-default-footer
                            disable-sort
                            class="mx-12"> 
                                <template v-slot:[`item.subPara`]={item}>
                                    <v-icon @click.stop="toSubList(item)" >mdi-view-list</v-icon>
                                </template>
                                <template v-slot:[`item.paraDel`]={item}>
                                    <v-icon @click.stop="toDel(item)">mdi-delete-forever</v-icon>
                                </template>
                                <template v-slot:[`item.paraEdit`]={item}>
                                    <v-icon @click.stop="toEdit(item)" >mdi-square-edit-outline</v-icon>
                                </template>
                            </v-data-table>
                        
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getSysParamList, delSysParam} from "@/api/hyirecoApi";

export default {
  name: 'paraList',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
    };
  },
  destroyed() {
        clearInterval(this.interval);
        window.removeEventListener('popstate', this.getDataList, false);
  },
  mounted(){
        this.userName = this.loginAuth.memberName;
        this.getDataList();
        if (window.history && window.history.pushState) {
            console.log("history");
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.getDataList, false);
        }
  },
  computed: {
    headers() {
      return [
          {
            text: '參數名稱',
            align: 'left',
            value: 'name',
            class: "subtitle-2 font-weight-black",
          },
          { text: '參數值', value: 'code', class: "subtitle-2 font-weight-black",width:150,},
          { text: '子參數', value: 'subPara', class: "subtitle-2 font-weight-black",width:80,},
          { text: '維護', value: 'paraEdit', class: "subtitle-2 font-weight-black",width:80,},
          { text: '刪除', value: 'paraDel', class: "subtitle-2 font-weight-black",width:80, },
      ]
    },
  },
  methods: {
    getDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        var queryObj = new Object();
        if (vm.$route.params.paraId === "root") {
            queryObj.mainParamId = "";
        } else {
            queryObj.mainParamId = vm.$route.params.paraId;
        }
        
        getSysParamList(queryObj, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                console.log("responseData = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    vm.datalist = responseData.sysParamList;
                    vm.mainParamId = responseData.mainParamId;
                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
    toSubList: function(value) {
        let vm = this;
        vm.$router.push({
            path: "/parameter/paraList/" + value.paramId
        });
        vm.getDataList();
    },
    toEdit: function(value) {
        let vm = this;
        vm.$router.push({
             path: "/parameter/paraEdit/" + vm.$route.params.paraId + "/" + JSON.stringify(value)
        });
    },
    toDel: function(value) {
        let vm = this;
        vm.$swal({
            title: "<font face='Microsoft JhengHei'>請確認</font>",
            html: "<font face='Microsoft JhengHei'>確認後將立即刪除 " + value.name + "</font>",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "<font face='Microsoft JhengHei' color='#fff'>確認</font>",
            cancelButtonText: "<font face='Microsoft JhengHei' color='#fff'>取消</font>",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
        }).then(function(result) {
            if (result.isConfirmed) {
                vm.isLoading = true;
                var id = value.paramId;

                delSysParam(id, vm.auth).then(function(response) {
                    if ("200" == response.status) { 
                        var responseData = response.data;
                        console.log("response = " + JSON.stringify(responseData));
                        vm.$swal(responseData.message);

                        if (responseData.statusCode === "1") {
                            vm.getDataList();
                        }
                    }
                    vm.isLoading = false;
                });    
            }
        });
    },
    toBack: function(){
        window.history.back();
        // let vm = this;
        // vm.$router.push({
        //     path: "/parameter/paraList/" + vm.mainParamId
        // });
        // vm.getDataList();
    },
    toCreate: function() {
        this.$router.push({
            path: "/parameter/paraEdit/" + this.$route.params.paraId + "/init" 
        });
    },
    keyupDelay: function() {
        //用於延遲查詢，避免value改變時連續送出request
        let vm = this;
        clearTimeout(vm.timer);
        
        vm.timer = setTimeout(function() {
            vm.getDataList();
        }, 800);
    },
  },
  watch: {
    
  },
  data: () => ({
    userName: "",
    listPageSize: 9999,
    datalist: [],
    status: "",
    timer: null,
    isLoading: false,
    auth:{},
    mainParamId: "",
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>

</style>
